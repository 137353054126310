<template>
  <div class="page-wrapper">
    <PageContent class="page-content" @update:page="handleChangePage" :contentTableConfig="contentTableConfig"
      :pageInfo="pageInfo" :pageName="pageName" @search="hanldeSearch" :searchList="searchList">
      <template #btn>
        <MyButton title="添加" :left="true" @click="hanldeAdd()">
          <template slot="preImage">
            <img src="@/unit/img/zj.png" alt="" />
          </template>
        </MyButton>
      </template>
      <template #operation="{ row }">
        <div>
          <pop tips="修改" @myclick="edit(row)">
            <img class="icon" src="@/assets/img/icon/edit.png" alt="" />
          </pop>
          <pop popLeft tips="删除" @myclick="del(row)">
            <img class="icon" src="@/assets/img/icon/deleted.png" alt="" />
          </pop>
          <!-- <pop popLeft tips="拉闸" @myclick="hanldeSwitch(row)">
            <img class="icon" src="@/assets/img/icon/lz.png" alt="" />
          </pop>
          <pop popLeft tips="余量清零" @myclick="hanldeClear(row)">
            <img class="icon" src="@/assets/img/icon/edit.png" alt="" />
          </pop> -->
        </div>
      </template>
    </PageContent>
    <PageModal ref="modalRef" :form-field-list="modalFormList" title="余量清零" @submit="handleSubmit"
      :sorceFormData="modalFormData"></PageModal>
    <PageModal labelWidth="60px" ref="addModalRef" :form-field-list="createModalFormList" :title="isAddTitle"
      @submit="handleAddSubmit" :sorceFormData="modalFormData" :selectOptions="stallOptions"></PageModal>
    <PageModal ref="switchmodalRef" title="拉闸确认" @submit="handleSwitchSubmit">
      <template #content>
        <div>拉闸后商户将会断电,确认需要拉闸么?</div>
      </template>
    </PageModal>
  </div>
</template>
<script>
import PageContent from "../cpns/pageContent.vue";
import PageModal from "../cpns/pageModal.vue";
import pageBaseMixin from "@/mixin/pageBase";
import { addMeter, editeMeter, getStallList, modifyMeter, delMeter } from "../service/meter";
import {
  contentTableConfig,
  searchList,
  modalFormList,
  createModalFormList,
} from "./config";

export default {
  components: {
    PageContent,
    PageModal,
  },
  mixins: [pageBaseMixin],
  data () {
    return {
      contentTableConfig,
      searchList,
      modalFormList,
      createModalFormList,
      modalFormData: {},
      pageName: "meterManage",
      type: 2,
      stallOptions: {},
      isAddTitle: '新增'
    };
  },
  methods: {
    /**
     * 删除
     * @param {参数} row 
     */
    del (row) {
      this.$confirm('此操作将永久删除该表号, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delMeter({
          id: row.meter_id
        }).then(() => {
          this.$common.notifySuccess("删除成功");
          this.getDataList();
        });
      });
    },
    /**
     * 修改表号或者商铺
     * @param {参数} row 
     */
    edit (row) {
      this.isAddTitle = '修改';
      this.modalFormData = {
        merchants_stall_id: row.merchants_stall_id,
        merchants_stall_name: row.merchants_stall_name,
        meter_number: row.meter_number,
        meter_id: row.meter_id
      };
      this.$refs.addModalRef.open();
    },
    hanldeAdd () {
      this.isAddTitle = '新增';
      this.$refs.addModalRef.open();
    },
    hanldeClear (row) {
      this.modalFormData = row;
      this.$refs.modalRef.open();
    },
    handleAddSubmit (value) {
      // const { merchants_stall_id } = value;
      // const select = this.stallOptions["merchants_stall_id"].find(
      //   (item) => item.merchants_stall_id == merchants_stall_id
      // );
      // console.log("select", select);
      if (value.meter_id) {
        // 修改
        modifyMeter({ ...value, meter_type: this.type }).then(() => {
          this.$common.notifySuccess("修改成功");
          this.getDataList();
          this.$refs.addModalRef.close();
        });
      } else {
        addMeter({ ...value, meter_type: this.type }).then(() => {
          this.$common.notifySuccess("新增成功");
          this.getDataList();
          this.$refs.addModalRef.close();
        });
      }
    },
    handleSubmit (value) {
      this.modalFormData = value;
    },

    hanldeSwitch () {
      this.$refs.switchmodalRef.open();
    },
    handleSwitchSubmit (value) { },
    async getStallList () {
      const res = await getStallList();
      this.stallOptions["merchants_stall_id"] = res?.data?.list.map((item) => ({
        ...item,
        value: item.merchants_stall_id,
        label: item.merchants_stall_number,
      }));
    },
  },
  mounted () {
    this.getStallList();
    this.getDataList();
  },
};
</script>
<style lang="scss" scoped>
:deep(.el-table .erro-row) {
  background: #f7c3c3;
}
</style>
