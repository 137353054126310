const tableRowClassName = ({ row, rowIndex }) => {
  if (row.surplus_amout <= 20) {
    return "erro-row";
  }
};

const contentTableConfig = {
  propList: [
    { prop: "meter_number", label: "表号", minWidth: "80" },

    {
      prop: "merchants_stall_name",
      label: "商铺名称",
    },
    {
      prop: "price",
      label: "单价(元)",
    },
    {
      prop: "total_amout",
      label: "总量",
    },
    {
      prop: "total_price",
      label: "总价",
    },
    {
      prop: "used_amout",
      label: "已用总量",
    },
    {
      prop: "used_price",
      label: "已用总价",
    },
    // {
    //   prop: "read_time",
    //   label: "数据采集时间",
    // },
    {
      prop: "create_at",
      label: "创建时间",
    },
    {
      slotName: "operation",
      label: "操作",
      minWidth: "100",
    },
  ],
  showIndexColumn: false,
  showSelectColumn: false
  //childrenProps 将直接传入elTable
  // childrenProps: {
  //   rowClassName: tableRowClassName,
  // },
};
const searchList = [
  {
    type: "input",
    placeholder: "请输入商铺名称",
    field: "merchants_stall_name",
  },
  {
    type: "input",
    placeholder: "请输入电表号",
    field: "meter_number",
  },
  // {
  //   type: "select",
  //   placeholder: "请选择合闸状态",
  //   field: "type",
  //   options: [
  //     { label: "合闸", value: 0 },
  //     { label: "拉闸", value: 1 },
  //   ],
  // },
  // {
  //   type: "select",
  //   placeholder: "请选择联网状态",
  //   field: "type",
  //   options: [
  //     { label: "连接", value: 0 },
  //     { label: "断开", value: 1 },
  //   ],
  // },
  // {
  //   type: "radio",
  //   placeholder: "",
  //   field: "radio",
  //   options: [
  //     {
  //       label: 1,
  //       text: "大于",
  //     },
  //     {
  //       label: 2,
  //       text: "小于",
  //     },
  //   ],
  //   label: "剩余",
  // },
  // {
  //   type: "input",
  //   placeholder: "请输入用量",
  //   field: "elec",
  // },
  // {
  //   type: "radio",
  //   placeholder: "",
  //   field: "radio",
  //   options: [
  //     {
  //       label: 1,
  //       text: "大于",
  //     },
  //     {
  //       label: 2,
  //       text: "小于",
  //     },
  //   ],
  //   label: "使用",
  // },
  // {
  //   type: "input",
  //   placeholder: "请输入用量",
  //   field: "elec",
  // },
  {
    type: "daterange",
    placeholder: "选择时间",
    field: "time",
  }
];

const modalFormList = [
  {
    type: "radio",
    label: "是否需要退款",
    field: "isRefund",
    options: [
      {
        label: 1,
        text: "是",
      },
      {
        label: 2,
        text: "否",
      },
    ],
    requireRules: true, //是否使用rules
    rules: [{ required: true, message: "请选择是否退款", trigger: "blur" }],
  },
  {
    type: "input",
    label: "剩余电量(度)",
    field: "surplus_amout",

    disabled: true,
  },
  {
    type: "input",
    label: "剩余金额(元)",
    requireRules: true, //是否使用rules
    field: "surplus_price",
    disabled: true,
  },
  {
    type: "input",
    label: "退款金额",
    field: "refundMoney",
    rules: [{ required: true, message: "请输入金额", trigger: "blur" }],
    placeholder: "请输入退款金额",
  },
  {
    type: "textarea",
    label: "备注",
    field: "marks",
    placeholder: "请输入备注",
  },
];
const createModalFormList = [
  {
    type: "input",
    label: "表号",
    field: "meter_number",
    requireRules: true,
    placeholder: "请输入表号",
    rules: [{ required: true, message: "请输入表号", trigger: "blur" }],
  },
  {
    type: "select",
    label: "商铺",
    field: "merchants_stall_id",
  },
];
export { contentTableConfig, searchList, modalFormList, createModalFormList };
